.shakaDesignsLlp {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}

.footer {
  flex: 1;
  background-color: var(--secondary-color-04);
  flex-direction: row;
  padding: 10.5px 0;
  max-width: 100%;
  z-index: 2;
}

.footer,
.footerContainer,
.mainDesign {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.footerContainer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs);
  max-width: 100%;
}

.mainDesign {
  width: 100%;
  position: relative;
  background-color: var(--neutral-08);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 var(--padding-12xs);
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--body-16px-regular-size);
  font-weight: 300;
  letter-spacing: -0.03em;
  color: var(--neutral-02);
  font-family: var(--body-18px-regular);
}

.bgIcon {
  align-self: normal;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.content,
.heroSection {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  width: 50%;
  margin: 0 !important;
  position: absolute;
  top: 20%;
  left: 10%;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  z-index: 1;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-12xs) 0;
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  max-height: 52em;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: 80px;
  color: var(--neutral-01);
  font-family: var(--body-18px-regular);
}

@media screen and (max-width: 750px) {
  .getStarted1 {
    font-size: var(--font-size-29xl);
  }
  .getStarted {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .getStarted1 {
    font-size: var(--font-size-17xl);
  }
  .experienceYourDream {
    font-size: var(--body-16px-regular-size);
    line-height: 27px;
  }
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 1200px) {
  .content {
    /* width: 60%; */
    /* left: 5%; */
  }

  .heroSection {
    font-size: 80px;
  }

  .bgIcon {
    height: 100vh;
  }
}

@media screen and (max-width: 992px) {
  .content {
    /* width: 70%; */
    top: 150px;
  }

  .heroSection {
    font-size: 60px;
  }

  .bgIcon {
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  .content {
    /* align-items: normal; */
    top: 300px;
    /* left: 10%; */
  }

  .heroSection {
    font-size: 50px;
    /* text-align: right; */
  }

  .bgIcon {
    object-position: right;
    height: 100vh;
  }
}

@media screen and (max-width: 576px) {
  .content {
    top: 30%;
    left: 0px;
    width: 95%;
    align-items: normal;
  }

  .heroSection {
    font-size: 40px;
    text-align: right;
  }
  .bgIcon {
    object-position: right;
    height: 100vh;
  }

  .footer {
    padding: 5px 0;
  }
}
