.icon {
  height: 56px;
  width: 56px;
  position: relative;
}
.childContainer {
  margin: 0 !important;
  width: 120px;
  position: absolute;
  bottom: 14.5px;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}
.elementGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini-5) var(--padding-20xl) var(--padding-45xl-5);
  position: relative;
  gap: var(--gap-11xs);
  flex-wrap: nowrap;
}
@keyframes slide {
  /* from {
    right: max(calc(200px * 6), 500%);
  } */
  to {
    /* transform: translateX(-200%); */
    left: -200px;
  }
}
.bar {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-around;
  padding: var(--padding-51xl) 0;
  gap: var(--gap-xl);
}
.highlights {
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: var(--body-20px-regular-size);
  color: var(--color-black);
  font-family: var(--body-18px-regular);
  background-color: var(--secondary-color-04);
}
@media screen and (max-width: 800px) {
  .bar {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .elementGroup {
    position: absolute;
    width: 100px;
    height: 150px;
    left: max(calc(100px * 8), 100%);
    animation-name: slide;
    animation-timing-function: linear;
    animation-duration: 15s;
    animation-iteration-count: infinite;
  }
}
@media screen and (max-width: 450px) {
  .bar {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .elementGroup {
    position: absolute;
    width: 100px;
    height: 150px;
    left: max(calc(100px * 8), 100%);
    animation-name: slide;
    animation-timing-function: linear;
    animation-duration: 15s;
    animation-iteration-count: infinite;
  }
}
