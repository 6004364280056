.blog {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--secondary-color-04);
  overflow: hidden;
  flex-direction: column;
  padding: 5rem 1rem;
  gap: 60px;
  box-sizing: border-box;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .blog {
    padding: 3rem 0.6rem;
    gap: 30px;
  }
}

@media screen and (max-width: 400px) {
  .blog {
    padding: 2.5rem 0.5rem;
    gap: 25px;
  }
}
