.bgVideo,
.servicesIntro {
  align-self: stretch;
  max-width: 100%;
}
.servicesIntro {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 75px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h6-60-size);
  color: var(--neutral-01);
  font-family: var(--body-18px-regular);
}
.bgVideo {
  flex: 1;
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.craftedBeauty,
.play1Icon {
  position: relative;
  z-index: 1;
}
.craftedBeauty {
  margin: 0;
  flex: 1;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;
  min-width: 628px;
  max-width: 100%;
}
.play1Icon {
  width: 72px;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
}
.banner,
.play1Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.play1Wrapper {
  flex-direction: column;
  padding: 101px 0 0;
}
.banner,
.blog {
  box-sizing: border-box;
  max-width: 100%;
}
.banner {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5rem 2rem 5rem 2rem;
  position: relative;
  gap: var(--gap-36xl);
  text-align: center;
  font-size: 100px;
  color: var(--neutral-08);
  font-family: var(--body-18px-regular);
}
.blog {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--secondary-color-04);
  overflow: hidden;
  flex-direction: column;
  padding: 5rem 0 5rem 0;
  gap: 60px;
}
.shakaDesignsLlp {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}
.footer {
  flex: 1;
  background-color: var(--secondary-color-04);
  flex-direction: row;
  padding: 10.5px 0;
  max-width: 100%;
  z-index: 2;
}
.footer,
.footerContainer,
.mainDesign {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.footerContainer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs);
  max-width: 100%;
}
.mainDesign {
  width: 100%;
  position: relative;
  background-color: var(--neutral-08);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 var(--padding-12xs);
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--body-16px-regular-size);
  color: var(--neutral-02);
  font-family: var(--body-18px-regular);
}
@media screen and (max-width: 1125px) {
  .craftedBeauty {
    min-width: 100%;
  }
  .blog {
    padding-bottom: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .aBriefOverview {
    font-size: var(--font-size-29xl);
  }
  .overviewContentParent {
    gap: var(--gap-xl);
  }
  .craftedBeauty {
    font-size: 80px;
  }
  .blog {
    box-sizing: border-box;
  }
  .blog {
    gap: 30px;
    padding-left: var(--padding-21xl);
    padding-bottom: 27px;
  }
}
@media screen and (max-width: 450px) {
  .aBriefOverview {
    font-size: var(--font-size-17xl);
  }
  .craftedBeauty {
    font-size: 60px;
  }
  .blog {
    gap: 15px;
  }
}
