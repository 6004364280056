@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body-18px-regular: Urbanist;

  /* font sizes */
  --body-16px-regular-size: 16px;
  --font-size-sm: 14px;
  --font-size-21xl: 40px;
  --font-size-5xl: 24px;
  --body-32px-regular-size: 32px;
  --body-18px-regular-size: 18px;
  --h4-70-size: 70px;
  --font-size-23xl: 42px;
  --font-size-37xl: 56px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --body-28px-semibold-size: 28px;
  --font-size-3xl: 22px;
  --h6-60-size: 60px;
  --font-size-17xl: 36px;
  --font-size-29xl: 48px;
  --font-size-11xl: 30px;
  --body-20px-regular-size: 20px;
  --body-34px-regular-size: 34px;
  --font-size-8xl: 27px;
  --font-size-101xl: 120px;

  /* Colors */
  --neutral-08: #fff;
  --secondary-color-04: #e9eadd;
  --neutral-03: #5d5d5d;
  --neutral-02: #4d4d4d;
  --neutral-01: #181818;
  --color-gray-100: rgba(0, 0, 0, 0.3);
  --color-gray-200: rgba(0, 0, 0, 0.1);
  --color-silver: #bbb;
  --color-black: #000;
  --neutral-04: #a0a0a0;
  --color-yellow: #eff422;

  /* Gaps */
  --gap-xl: 20px;
  --gap-63xl: 82px;
  --gap-22xl: 41px;
  --gap-6xl: 25px;
  --gap-2xs-2: 10.2px;
  --gap-36xl: 55px;
  --gap-8xl: 27px;
  --gap-45xl: 64px;
  --gap-98xl: 117px;
  --gap-xs: 12px;
  --gap-16xl: 35px;
  --gap-mid: 17px;
  --gap-31xl: 50px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-3xs: 10px;
  --gap-53xl: 72px;
  --gap-lg: 18px;
  --gap-17xl: 36px;
  --gap-56xl: 75px;
  --gap-lgi: 19px;
  --gap-18xl: 37px;
  --gap-15xl: 34px;
  --gap-27xl: 46px;
  --gap-4xl: 23px;
  --gap-6xs-8: 6.8px;
  --gap-7xs-1: 5.1px;
  --gap-12xs-8: 0.8px;
  --gap-11xs: 2px;
  --gap-12xl: 31px;

  /* Paddings */
  --padding-12xs: 1px;
  --padding-18xl: 37px;
  --padding-31xl: 50px;
  --padding-5xl: 24px;
  --padding-6xl: 25px;
  --padding-23xl-5: 42.5px;
  --padding-11xl: 30px;
  --padding-41xl: 60px;
  --padding-xs-8: 11.8px;
  --padding-40xl: 59px;
  --padding-xl: 20px;
  --padding-13xl: 32px;
  --padding-61xl: 80px;
  --padding-21xl: 40px;
  --padding-78xl: 97px;
  --padding-44xl: 63px;
  --padding-72xl: 91px;
  --padding-5xs-5: 7.5px;
  --padding-mini: 15px;
  --padding-4xl: 23px;
  --padding-11xs: 2px;
  --padding-9xs-9: 3.9px;
  --padding-64xl: 83px;
  --padding-51xl: 70px;
  --padding-26xl: 45px;
  --padding-191xl: 210px;
  --padding-117xl: 136px;
  --padding-147xl: 166px;
  --padding-22xl: 41px;
  --padding-6xs: 7px;
  --padding-10xl: 29px;
  --padding-3xs-5: 9.5px;
  --padding-33xl: 52px;
  --padding-2xs: 11px;
  --padding-base: 16px;
  --padding-smi-8: 12.8px;
  --padding-19xl: 38px;
  --padding-sm: 14px;
  --padding-20xl: 39px;
  --padding-43xl-8: 62.8px;
  --padding-smi: 13px;
  --padding-47xl: 66px;
  --padding-mini-5: 14.5px;
  --padding-45xl-5: 64.5px;
  --padding-lg-2: 18.2px;
  --padding-lg-3: 18.3px;
  --padding-xl-7: 20.7px;

  /* Border radiuses */
  --br-6xl: 25px;
}
