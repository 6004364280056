.residential {
  margin: 0;
  position: relative;
  font-size: clamp(1.5rem, 3vw, 2rem);
  text-transform: capitalize;
  font-weight: 600;
  font-family: inherit;
}

.typeItems {
  width: 4px;
  height: 4px;
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-01);
}

.typeList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 0 0;
}

.commercial,
.renovations {
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  min-width: min-content;
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.typeList1,
.typeList2,
.typeList3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.25rem 0 0;
}

.projectTypes {
  width: 100%;
  max-width: 644px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-sizing: border-box;
  gap: 1rem;
  flex-wrap: wrap;
}

.imageIcon,
.imageIcon1,
.imageIcon2 {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
}

.livingRoom,
.bedroom,
.balcony {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: clamp(1.25rem, 3vw, 2rem);
  text-transform: capitalize;
  font-weight: 300;
  font-family: inherit;
  display: inline-block;
}

.div,
.div1,
.div2 {
  width: 100%;
  max-width: 685px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 2rem;
  box-sizing: border-box;
  gap: 0.5rem;
}

.blog {
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  overflow-x: visible;
}

.projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  text-align: left;
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--neutral-01);
  font-family: var(--body-18px-regular);
}

@media screen and (min-width: 768px) {
  .blog {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .div,
  .div1,
  .div2 {
    flex: 1 1 300px;
  }
}

@media screen and (max-width: 600px) {
  .projectTypes {
    justify-content: center;
  }

  .commercial,
  .renovations {
    min-width: auto;
  }
}
