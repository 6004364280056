.portfolioTitleContainer {
  height: 45px;
  width: 130px;
}
.portfolioTitleContainerWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}
.trustTheProcess {
  margin: 0;
  width: 425px;
  position: relative;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 300;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  min-width: 425px;
  max-width: 100%;
}
.atShakaDesign,
.blankLine {
  margin: 0;
}
.seeOurProcess1 {
  text-decoration: underline;
}
.seeOurProcess {
  margin: 0;
}
.atShakaDesignContainer {
  align-self: stretch;
  position: relative;
  text-transform: capitalize;
}
.atShakaDesignLabWeBelievWrapper {
  width: 533px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs-9) 0 0;
  box-sizing: border-box;
  min-width: 533px;
  max-width: 100%;
  font-size: var(--body-18px-regular-size);
  color: var(--color-black);
}
.headTitle,
.process {
  display: flex;
  max-width: 100%;
}
.process {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.headTitle {
  width: 24em;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: var(--padding-21xl) var(--padding-4xl) 0 var(--padding-11xs); */
  box-sizing: border-box;
  gap: var(--gap-53xl);
  text-align: left;
  font-size: var(--h6-60-size);
  color: var(--neutral-01);
  font-family: var(--body-18px-regular);
}
@media screen and (max-width: 1125px) {
  .process {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  .trustTheProcess {
    font-size: var(--font-size-29xl);
    min-width: 100%;
  }
  .atShakaDesignLabWeBelievWrapper {
    min-width: 100%;
  }
  .headTitle {
    gap: var(--gap-17xl);
  }
}
@media screen and (max-width: 450px) {
  .trustTheProcess {
    font-size: var(--font-size-17xl);
  }
  .headTitle {
    gap: 20px;
  }
}
